<template>
  <component :is="tag">
    <NuxtLazyHydrate
      v-for="(item, index) of filteredData"
      :key="item._uid"
      :when-visible="hydrateOnIdle.includes(item.component) ? null : { rootMargin: '50px' }"
      :when-triggered="hydrateOnIdle.includes(item.component) ? true : null"
    >
      <!-- :when-idle="hydrateOnIdle.includes(item.component) ? 100 : null" -->
      <component
        :is="item.component"
        v-editable="item"
        v-anchors="{ item, index }"
        :layout="item.layout"
        :data="!angosticComponents.includes(item.component) ? item : null"
        v-bind="angosticComponents.includes(item.component) ? item : null"
        @navigate-next="emit('navigate-next')"
        @navigate-prev="emit('navigate-prev')"
      />
    </NuxtLazyHydrate>
  </component>
</template>

<script setup>
const angosticComponents = [
    'ShopProductStatic',
    'BlockBlogCurated',
];

const hydrateOnIdle = [
    'AuthUpdatePharmacy',
    'BlockAuthor',
    'BlockBrandsList',
    'BlockContactCard',
    'BlockFolderLinks',
    'BlockJobsList',
    'BlockProductsFlyout',
    'BlockTeaserBlog',
    'ShopProductRelatedProducts',
    'ShopBundleSpotlight',
    'ShopProductsMix',
    'BlogBlogCurated',
];

const props = defineProps({
    data: {
        type: Array,
        default: () => [],
    },
    tag: {
        type: String,
        default: 'div',
    },
    hideHeading: {
        type: Boolean,
        default: false,
    },
});

/*
  Filter data
*/
const filteredData = computed(() => {
    if (props.hideHeading) {
        const index = props.data.findIndex((item) => item.component === 'AtomHeading');

        /* remove index from array */
        return props.data.filter((item, i) => i !== index);
    }
    return props.data;
});

const emit = defineEmits(['navigate-next', 'navigate-prev']);
</script>
